<template>
  <div id="imgUpload">
    <el-upload
      v-loading="loading"
      :action="action"
      :headers="{ ['Authorization']: 'Bearer ' + token }"
      :show-file-list="false"
      :before-upload="
        () => {
          loading = true;
        }
      "
      :on-error="
        () => {
          loading = false;
        }
      "
      :on-success="handleSuccess"
    >
      <img
        v-if="previewUrl"
        :src="previewUrl"
        class="avatar"
        :key="previewUrl"
      />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      loading: false,
      previewUrl: null,
      action: `${process.env.VUE_APP_BASE_URL}ad/pic/upload`,
    };
  },
  methods: {
    handleSuccess(res) {
      if (res.code === 0) {
        this.previewUrl = res.data.fileUrl;
        this.$emit("success", res.data.fileId);
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
#imgUpload {
  .el-upload {
    width: 100%;
    height: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border-color: $main-color;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
    }
    .avatar {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }
  }
}
</style>
